.location-container{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding-top: 2vw;
    padding-bottom: 2vw;
    margin-top: 2vw;

    box-shadow: 0px 0px 50px rgb(174, 170, 170);
    
}

.location-container::before {
    content: '';
    width: 100%;
    background-image: url(../img/background/bg_loc_1.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    
    z-index: -1;
    filter: blur(5px);
    opacity: 0.2;

    

    
  }



.location-content{
   
    width: 31%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
}

.content-img{
    width: 100%;
    margin-top: -3vw;
    z-index: 1;

}

.content-text{
    backdrop-filter: blur(5px);
    z-index: 2;
    width: 100%;
    font-family: 'Open-Sans', sans-serif;
    color: #fff;
    font-weight: 500;
    font-size: 2vw;
    background-color: #d10000b3;

    text-align: center;


    
}


@media (max-width: 920px) {
    .location-container{
        flex-wrap: wrap;
    }

    .location-content{
        width: 43%;
    }

    .location-content:last-child{
       margin-top: 4vw;
    }

    .content-text{
        font-size: 3vw;
    }
}

@media (max-width: 620px) {
    .location-container{
        flex-direction: column;
        align-items: center;
        
    }

    .location-content{
        margin-top: 3rem;
        width: 80%;
    }

    .content-text{
        font-size: 2rem;
    }
}
