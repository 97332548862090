.about{
    margin-bottom: 10%;

}

.hidden{
    margin-top: 10px;
    overflow: hidden;
}


.about_header{
    
    text-align: center;
  
    padding-top: 110px;
    padding-bottom: 50px;

}

.about_headerH1{
   
    font-size: 400%;
}


.about_headerH1::after{
    content: "";
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 17%;
    height: 10px;
    margin-top: 20px;

    border-radius: 10px;
  
    background-color: #D10000;
}
.about_text{
    padding-right: 5%;
    padding-left: 5%;
    margin-bottom: 5%;
    text-align: center;

    font-size: 250%;
}


/* STarter icons ========================================================================= */

.s_icons{
   margin-top: 5%;
   display: block; 
   margin-left: auto;
   margin-right: auto;
   width: 15%;
}


@media (max-width: 990px){
    .about_headerH1{
   
        font-size: 300%;
    }

    .about_text{
        font-size: 150%;
    }
}

@media (max-width: 620px){
    .about_headerH1{
   
        font-size: 200%;
    }

    .about_text{
        font-size: 100%;
    }
}