.contacts {
    display: flex;
    flex-direction: column;
}

.contacts-container {
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: 'Open-Sans', sans-serif;
    font-weight: 400;
    font-size: 2vw;
    padding-top: 5vw;
    padding-bottom: 5vw;
    overflow: hidden;
}

.content-contacts {
    display: flex;
    justify-content: space-around;
}

.content-1 {
    display: flex;
    justify-content: center;
}

.contacts .icon {
    width: 3vw;
    margin-right: 1vw;
}
.content-0{
    text-align: center;
    cursor: pointer;
    z-index: 2;
}
.content-1,
.content-2,
.content-3,
.content-4 {
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 2;
}

.content-1{
    margin-bottom: 3vw;
}

.contacts-container-window {
    width: 100%;
    padding-top: 3vw;
    padding-bottom: 3vw;
    box-shadow: 0px 0px 10px #ccc;
}
.content-2 a,
.content-3 a,
.content-4 a{
    text-decoration: none;
    font-family: 'Open-Sans', sans-serif;
    font-weight: 400;
    
    color: black;
}
@media (max-width:760px){
    .contacts-container{
        font-size: 3vw;
    }
}

@media (max-width: 620px) {
   

    .contacts .icon {
        width: 1.5rem;
        margin-right: 1vw;
    }
}

@media (max-width: 530px) {
    .content-contacts {
        flex-direction: column;
        align-items: center;
    }
    .contacts-container {
        font-size: 1.5rem;
    }
    .content-2,
    .content-3,
    .content-4 {
        margin-top: 2rem;
    }
    
}