.utils-header{
    margin-top: 2vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.utils-icon{
    height: 10vw;
}
.utils-text{
    text-align: center;
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 2vw;
    text-wrap: nowrap;
}
.utils-text::after{
    content: "";
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
    height: 10px;
    border-radius: 10px;
    background-color: #D10000;
}
@media (max-width: 1024px) {
    .utils-header{
        margin-top: 2rem;
    }
    .utils-icon{
        height: 10rem;
    }
    .utils-text{
        font-size: 2rem;
    }
}

@media (max-width: 768px) {
    .utils-icon{
        height: 8rem;
    }
    .utils-text{
        font-size: 1.5rem;
    }
    
}