.utils-service-slider{
    box-shadow: 0px 0px 30px #ccc;
    width: 100%;
    margin-bottom: 4%;
}

.slide-text{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5vw;
    font-weight: 400;
    font-family: 'Open Sans';
    text-align: center;
    font-size: 1.5vw;
    z-index: 2;
    height: 8vw;
    

    background-color: #D10000;
    color: #fff;
}

.slide-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    filter: blur(5px);
    z-index: 0;
    background-size: cover;
}

.slide-img-container{
    display: flex;
    align-content: center;
    justify-content: center;
    height: 15vw;
    align-items: center;
}

.slide-img{
    height: 10vw;
    width: 10vw;
}

.utils-service-slider .carousel-indicators [data-bs-target] {
    background-color: #0a0a0a;
}

.utils-service-slider .carousel-control-next-icon {
    fill: black;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.utils-service-slider .carousel-control-prev-icon {
    fill: black;
   background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e");
}






@media (max-width:860px) {
    .services-content {
        width: 100%;
        margin-top: 3vw;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        flex-flow: wrap;
    }

    .w100{
        width: 45%;
    }

 

    .carousel-indicators{
        margin-bottom: 0rem;
    }
}

@media (max-width:760px) {
    .slide-text{
        font-size: 0.9rem;
    }
}

@media (max-width:620px) {
    .services-content {
        flex-wrap: nowrap;
        justify-content: flex-start;
        flex-flow: column;
    }
    .utils-service-slider{
        box-shadow: 0px 0px 30px #ccc;
        width: 100%;
    }

    .slide-img {
        height: 5rem;
        width: 5rem;
    }

    .slide-text{
        font-size: 1rem;
        height: 4.2rem;
    }

    .carousel-indicators{
        margin-bottom: -0.5rem;
        
    }
    

    .w100{
        width: 95%;
    }

    .slide-img-container{
        height: 7rem;
    }

    
}