body::-webkit-scrollbar {
    width: 0;
    display: none;  
}
.header {
    position: absolute;
    width: 100%;
    height: 86%;
    z-index: 7;
    background-image: url('../img/background/bg_1.png');
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(5px);
    opacity: 0;
    user-select: none;
    -webkit-user-select: none;
    pointer-events: none;
}

.header-with-background {
    opacity: 0.05;
}

.NavbarBrand-container{
    position: relative;
}

.navbar-brand {
    margin-right: 2vw;
    --bs-navbar-brand-padding-y: 0;
}

#um {
    min-height: 2vw;
    height: 1.8rem;
}

#maz {
    min-height: 2vw;
    height: 1.8rem;
}

#jmz {
    min-height: 1vw;
    height: 1rem;
}

.navbar>.container {
    justify-content: flex-start;
    height: 4rem;
    z-index: 8;
}
.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 0px #ccc;
    background-color: #fff;
    --bs-navbar-padding-y: 0;
    z-index: 7;
    transition: all .6s;
    transform: translateY(-100%);
}
.navbar-nav{
margin-right: 0;
}

.navbar-open{
    transform: translateY(0);
    box-shadow: 0px 0px 50px #ccc;
}

.navbar-collapse{
    background-color: #ececec;
}

@media (min-width: 992px) {
    .btn {
        font-size: 1.2vw;
        width: 100%;
        align-items: center;
    }
    .navbar-collapse{
        background-color: transparent;
    }
    .navbar-nav{
        margin-right: 2vw;
    }
    svg{
        display: none;
    }
    .btn:hover .line {
        width: 60%;
    }
    .navbar>.container {
        height: 3vw;
    }
}

.btn {
    --bs-btn-padding-x: 0;
    --bs-btn-padding-y: 0;
    --bs-btn-font-family: 'Open Sans';
    --bs-btn-font-weight: 500;
    --bs-btn-font-size: 1.2rem;
    --bs-btn-border: none;
    --bs-btn-border-radius: none;
    min-height: 3vw;
    height: 3rem;
    min-width: 10vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 8;
}

.btn:hover .line {
    width: 40%;
}
.btn:hover svg {
    fill: #fff;
}
svg{
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    fill: rgba(22, 22, 22, 0.76);
    transition: all .3s;
}
.btn img{
    fill: aqua;
    width: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.btn label{
    display: flex;
    align-items: center;
}

.btn-primary {
    --bs-btn-color: #000000;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #D10000;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #bd2626;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #000000;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: transparent;
}

.line {
    display: block;
    width: 0;
    height: 0.15vw;
    background-color: #ffffff;
    transition: all .6s;
}

.navbar-toggler {
    border: 0 solid var(--bs-navbar-toggler-border-color);
    position: absolute;
    right: 0;
    z-index: 8;
}