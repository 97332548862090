.services-container{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.services-content{
    margin-top: 3vw;
    display: flex;

    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

}

.w100{
    width: 30%;
}

@media (max-width:620px) {

    

    .w100_hidden{
        display: none;
    }

    
    
    
}


