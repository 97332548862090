.information-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 30vw;
    background-color: transparent;
    align-items: center;
}

.hidden{
    overflow: hidden;
}

.window {
    overflow: hidden;
    box-shadow: 0px 0px 3vw #ccc;
    width: 100%;
    height: 80%;
}

.content {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 80%;
    align-items: center;
    justify-content: space-around;
}

.left{
    flex-direction: row-reverse;
    margin-right: 2rem;
}

.background-image {
    width: 100%;
    height: 150%;
    filter: blur(0px);
    opacity: 0.1;
}

.content-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
}

.text-container {
    display: flex;
    width: 50%;
    height: 100%;
    align-items: center;
}

.text {
    background-color: #d10000;
    font-family: 'Open Sans';
    font-weight: 400;
    padding: 2vw;
    font-size: 2vw;
    border-radius: 2vw;
    box-shadow: 10px 10px 0px #FED533;
    color: #fff;
}

.foreground-image {
    position: relative;
    height: 100%;
}

@media(max-width: 768px) {
    .window {
        height: 80%;
    }

    .content {
        height: 80%;
    }

    .foreground-image {
        height: 100%;
    }

    .text-container {
        width: 90%;
    }

    .text {
        width: 21rem;
        margin-left: 2rem;
        padding: 0.6rem;
        font-size: 1rem;
        border-radius: 1rem;
        box-shadow: 0.3rem 0.3rem 0px #FED533;
    }
}

@media(max-width: 670px) {
    .foreground-image {
        margin-top: 1rem;
        height: 10rem;
    }
    .text {
        padding: 1rem;
        font-size: 0.8rem;
        max-height: 90%;
        width: 85%;
        border-radius: 1rem;
        box-shadow: 0.3rem 0.3rem 0px #FED533;
    }
}

@media(max-width: 600px) {
    .information-container{
        height: 30rem;
    }
    .window {
        height: 80%;
    }

    .content {
        height: 80%;
        flex-direction: column-reverse;
    }
    .foreground-image {
        margin-top: 1rem;
        height: 10rem;
    }

    .text-container {
        margin-right: 1rem;
        width: 100%;
    }

    .text {
        width: 100%;
        padding: 1rem;
        font-size: 1rem;
        border-radius: 1rem;
        box-shadow: 0.3rem 0.3rem 0px #FED533;
    }
    .left{
        margin-right: 1rem;
    }
}
