img{
    padding: 0;
    padding-right: 0;
    margin-right: 0;
    user-select: none;
    -webkit-user-select: none;
    pointer-events: none;
}

.s1{
    width: 100%;
    padding: 0;
    margin: 0;

}

.carusel_shadow{
    padding-top: 5vw;
    z-index: -1;
    box-shadow: 10px 50px 150px #ccc;
}

@media (max-width:1100px){
    .carusel_shadow{
    padding-top: 6vw;
    }
}

@media (max-width:991px){
    .carusel_shadow{
    padding-top: 8vw;
    }
}

@media (max-width:865px){
    .carusel_shadow{
    padding-top: 9vw;
    }
}

@media (max-width:750px){
    .carusel_shadow{
    padding-top: 5rem;
    }
}

