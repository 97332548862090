.Footer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 3vw;
    width: 100%;
    height: 20vw;
    box-shadow: inset 0px 0px 2vw #ccc;
    align-items: center;
}

.Footer::before {
    content: '';
    position: absolute;
    background-image: url('../img/background/bg_1.png');
    background-size: cover;
    opacity: 0.15;
    filter: blur(5px);
    width: 100%;
    height: inherit;
}

.footer-map,
.footer-information,
.footer-services,
.footer-partners {
    width: 20%;
    height: 95%;
    border-left: solid #373737 3px;
    border-right: solid #373737 3px;
}

.footer-map {
    width: 35%;
    z-index: 5;
}

.footer-map-mobile {
    position: relative;
    display: none;
    z-index: 5;
    width: 100%;
    height: 20rem;
    margin-top: 5rem;
    margin-bottom: 2rem;
    box-shadow: 0px 0px 10px #ccc;
}

.footer-label {
    margin-bottom: 1vw;
    width: 100%;
    text-align: center;
    font-family: 'Open-Sans', sans-serif;
    font-weight: 500;
    font-size: 1.5vw;
}

.footer-text {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    font-family: 'Open-Sans', sans-serif;
    font-weight: 400;
    font-size: 1vw;
    margin-bottom: 1vw;
    margin-top: 1vw;
}

.footer-text-list {
    text-align: left;
    font-family: 'Open-Sans', sans-serif;
    font-weight: 400;
    font-size: 1vw;
}

.footer-icon {
    width: 2vw;
    margin-right: 1vw;
}

.footer-content-scroll::-webkit-scrollbar{
    display: none;
}
.footer-content-scroll{
    position: relative;
    overflow: scroll;
    z-index: 10;
    height: 80%;
}


@media (max-width: 970px) {
    .Footer {
        margin-top: 2rem;
        height: 21rem;
    }
    .footer-text{
        font-size: 0.9rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .footer-map {
        display: none;
    }

    .footer-map-mobile {
        display: block;
    }

    .footer-map,
    .footer-information,
    .footer-services,
    .footer-partners {
        width: 30%;
        height: 95%;
    }

    .footer-text-list {
        font-size: 0.7rem;
    }
    .footer-services,
    .footer-partners{
        overflow: hidden;
    }
    .footer-content-scroll::-webkit-scrollbar{
        display: none;
    }
    .footer-content-scroll{
        overflow: scroll;
        z-index: 5;
        height: 100%;
    }
    .footer-label{
        font-size: 1rem;
    }
}

@media (max-width: 520px) {
    .Footer {
        flex-direction: column-reverse;
        height: auto;
    }
    .footer-map,
    .footer-information,
    .footer-services,
    .footer-partners {
        width: 100%;
        height: 30%;
        border-top: solid #373737 0px;
        border-bottom: solid #373737 0px;
        border-left: solid #373737 3px;
        border-right: solid #373737 3px;
    }
    .footer-information{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .footer-partners{
        display: none;
    }
    .footer-services{
        display: none;
    }
    .footer-content{
        display: flex;
        margin-top: auto;
        margin-bottom: auto;
        flex-direction: column;
    }
    .footer-label{
        font-size: 2rem;
    }
    .footer-icon{
        width: 1rem;
    }
    .footer-text{
        font-size: 1rem;
    }
}